<template>
  <div :id="uid" class="panelTab">
    <slot></slot>
  </div>
</template>

<script>
// LIBS
import $ from "jquery";
import { v4 as uuidv4 } from 'uuid';

export default {
  name: "navigation-tab",
  props : ["changeActiveIndex"],

  data: function () {
    return {
      underlineRatio : 0.8,
      activeIndex : -2, // Value -2 TO FORCE FIRST UPDATED
      startWidth : 0,
      startUnderlineWidth : 0,
      startOffset : 0,
    };
  },
  created(){
      this.uid = uuidv4();
  },
  watch :{
    changeActiveIndex : function(val){
      if (val != this.activeIndex){
        this.activeIndex = val
      }
    },
    activeIndex : function(val){
        this.startWidth = ($("#" + this.uid + ".panelTab > *").eq(val).outerWidth());
        this.startUnderlineWidth = (this.startWidth * this.underlineRatio);
        this.startOffset = ($("#" + this.uid + ".panelTab > *").eq(val).position().left + ((this.startWidth - this.startUnderlineWidth)/2));

        //IF ACTIVE TAB NOT FOUND : Set width to 0
        if (val == -1){
          this.startUnderlineWidth = 0
        }

        // SET NEW UNDERLINE DEFAULT LEFT OFFSET
        // AND NEW UNDERLINE DEFAULT WIDTH
        // (FOR MOUSE OUT HOVER)
        $("#" + this.uid + ".panelTab").after().css({
          "--underline_leftOffset": this.startOffset + "px",
          "--underline_width": this.startUnderlineWidth + "px",
        })
    }
  },
  mounted(){
    //FIRST SETTING UP
    this.startWidth = ($("#" + this.uid + ".panelTab > *").eq(this.activeIndex).outerWidth());
    this.startUnderlineWidth = (this.startWidth * this.underlineRatio);
    this.startOffset = ($("#" + this.uid + ".panelTab > *").eq(this.activeIndex).position().left + ((this.startWidth - this.startUnderlineWidth)/2));
    
    //FISRT SETTING ACTIVE INDEX (WITH TIME OUT TO WAIT FOR ROUTER-LINK ADD "active" CLASS)
    setTimeout(()=>{
    
        var index = $("#" + this.uid + ".panelTab > *.active").index()
        this.activeIndex = index
   
    }, 50)

    // UPDATE activeIndex on click TO FORCE RENDER
    $("#" + this.uid + ".panelTab > *").on('click', (event)=>{
   
      this.activeIndex = $(event.target).index()
    })

    //SETTING UP HOVER / OUT HOVER ANIMATION
    $("#" + this.uid + ".panelTab > *").hover((event)=>{
      var target = $(event.target);
      var width = target.outerWidth();
      var underlineWidth = width * this.underlineRatio
      var leftOffset = target.position().left + ((width - underlineWidth)/2);

      // ON HOVER
      $("#" + this.uid + ".panelTab").after().css({
        "--underline_leftOffset": leftOffset + "px",
        "--underline_width": underlineWidth + "px",
      })
    }, ()=>{
      // OUT HOVER
      $("#" + this.uid + ".panelTab").after().css({
        "--underline_leftOffset": this.startOffset + "px",
        "--underline_width": this.startUnderlineWidth + "px",
      })
    })

    // SIMULATE MOUSEOVER ON ACTIVE TAB TO RENDER LINE WITH THE RIGTH WIDTH
    $("#" + this.uid + " > *").eq(this.activeIndex).trigger("mouseover");
  },
  updated(){
    //Change active index on class changing
    //newActiveIndex = $("#" + this.uid + ".panelTab > *.active").index()
    //console.log("Updated "+this.activeIndex + " to " + newActiveIndex)
  }
};
</script>

<style src="./navigation-tab.css" />