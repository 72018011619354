<template>
  <div class="main">
    <h1 style="margin-left: 64px; margin-top: 32px; margin-bottom:20px;">{{ $t('Profile.title') }}</h1>
    <navigationTab class="menu">
      <router-link :to="{ name: 'Profile.info' }">{{ $t('Profile.Info.title') }}</router-link>
      <router-link :to="{ name: 'Profile.pwd' }">{{ $t('Profile.ChangePwd.title') }}</router-link>
      <router-link :to="{ name: 'Profile.subscriptions' }">{{ $t('Profile.subscription.title') }}</router-link>
      <router-link v-if="getCurrentUser.team != undefined &&
      getCurrentUser.team.role == 0" :to="{ name: 'Profile.team' }">Team</router-link>
      <a v-if='billingPortal' @click="openBillingPortal()">{{ $t('Profile.billing.title') }}<img
          src="../../assets/external-link.png" /></a>
      <router-link :to="{ name: 'Profile.notifications' }">{{ $t('Profile.notifications.title') }}</router-link>
    </navigationTab>
    <router-view class="view" />
  </div>
</template>
<script>

import navigationTab from "../../components/Object/navigation-tab/navigation-tab.vue";


//STORE
import Vuex from "vuex";

export default {
  components: {
    navigationTab,
  },
  name: "Profil",

  computed: {
    ...Vuex.mapGetters(["getCurrentUser"]),
    billingPortal() {
      if (this.getCurrentUser.usr_subscription.stripe_id != undefined && this.getCurrentUser.usr_subscription.stripe_id != null) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    ...Vuex.mapActions(["openBillingPortal"]),
  }
};
</script>

<style scoped>
.main {
  height: calc(100% - 143px);
  height: 100%;
  position: relative;
  overflow-y: auto;
}

.view {
  overflow-y: visible;
  height: calc(100% - 143px);
}

.menu>a>img {
  height: 12px;
  margin-left: 5px;
  transition: filter 400ms;
  pointer-events: none;
}

.menu>a:not(:hover)>img {
  filter: contrast(0);
}
</style>

<style>

</style>

